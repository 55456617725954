import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';

import { Header } from '@components/Header';
import { Divider } from '@components/Divider';

import { UserDetails } from './UserDetails';
import { Teams } from './Teams';
import { Workspaces } from './Workspaces';
import { Organizations } from './Organizations';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { JobHistory } from './JobHistory';
import { useAPI } from '@hooks/useAPI';
import { UserService } from '../../../API';

const Content = styled.main`
  padding: 8px 30px 70px;
  min-height: calc(100vh - 120px);
`;

export const UserDetailsPage = () => {
  const { call } = useAPI();
  const { organization, me } = useAuthContext();
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const isVsAdmin = me?.roles?.ROLE_VS_ADMIN;

  const fetchData = async () => {
    if (!organization?.id || !userId) {
      console.log('getUserDetails: invalid params', organization?.id, userId);
      return;
    }
    setIsLoading(true);
    const { result: user } = await call(UserService.getUserDetails({ oid: organization?.id, userId }));
    setUser(user);
    setIsLoading(false);
  };

  const reload = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [organization, userId]);

  if (!user || isLoading) {
    return (
      <>
        <Helmet>
          <title>User Details - AutoScript</title>
        </Helmet>
        <Header title="User Details" />

        <LinearProgressLoader active={isLoading} />
        <Content></Content>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>User Details - AutoScript</title>
      </Helmet>
      <Header title="User Details" />

      <Content>
        <UserDetails user={user} reload={reload} />
        <Divider />
        {isVsAdmin ? (
          <>
            <Organizations user={user} />
            <Divider />
          </>
        ) : null}
        <Workspaces user={user} />
        <Divider />
        <Teams user={user} />
        <Divider />
        <JobHistory user={user} />
      </Content>
    </>
  );
};
